import gql from 'graphql-tag';
import {
	findIndex,
	pick,
	differenceBy,
	keys,
	isArray,
	isFunction,
	isObject
} from 'lodash';

import {
	useCreateFishboneItemMutation,
	useUpdateFishboneItemMutation,
	useDeleteFishboneItemMutation,
	useUpdateFishboneItemScenarioMutation
} from 'graph/dist/generated';
import { optimisticlyUpdateScenario } from 'utils/graphql';

export const useFishboneController = () => {
	const [createFishboneItem] = useCreateFishboneItemMutation();
	const [updateFishboneItem] = useUpdateFishboneItemMutation();
	const [deleteFishboneItem] = useDeleteFishboneItemMutation();
	const [updateFishboneItemScenario] =
		useUpdateFishboneItemScenarioMutation();

	const handleCreateFishboneItem = async (
		fishboneBranchId: string,
		values: Object,
		defaults = {}
	) => {
		let baseDefaults = {
			trend: '50',
			completion: 0,
			description: '',
			weight: 1
		};

		let appliedDefaults = {
			...baseDefaults,
			...defaults
		};

		let evaluatedAt = values.evaluatedAt || appliedDefaults.evaluatedAt;
		evaluatedAt =
			!values.evaluatedAt || !isFunction(values.evaluatedAt?.toISOString)
				? ''
				: new Date(values.evaluatedAt).toISOString();

		let variables = {
			fishboneBranchId: fishboneBranchId,
			name: values.name,
			weight: values.weight || 1,
			trend: values.trend || appliedDefaults.trend,
			completion: values.completion || appliedDefaults.completion,
			description: values.description || appliedDefaults.description,
			startOn:
				values.startOn && isObject(values.startOn)
					? values.startOn.format('YYYY-MM-DD')
					: values.startOn,
			endOn:
				values.endOn && isObject(values.endOn)
					? values.endOn.format('YYYY-MM-DD')
					: values.endOn,

			reference: values.reference || '',
			evaluatedAt: evaluatedAt
		};

		let result = await createFishboneItem({
			variables,
			update(cache, { data: { createFishboneItem } }) {
				cache.modify({
					id: `FishboneBranch:${fishboneBranchId}`,
					fields: {
						items(items = []) {
							const newFishboneItem = cache.writeFragment({
								id: 'FishboneItem:' + createFishboneItem.id,
								data: createFishboneItem,
								fragment: gql`
									fragment FishboneItemFragment on FishboneItem {
										name
										description
										weight
										trend
										completion
										startOn
										endOn
										createdAt
										reference
										scenarios
										evaluatedAt
									}
								`
							});

							return [...items, newFishboneItem];
						}
					}
				});
			},
			optimisticResponse: {
				createFishboneItem: {
					id: 'temp-id',
					...variables,
					createdAt: new Date().toISOString(),
					scenarios: []
				}
			}
		});
	};

	const handleUpdateFishboneItem = async (id, items, scenario, values) => {
		let index = findIndex(items, (s: any) => s.id === id);

		const newValues = {
			...pick(values, [
				'name',
				'description',
				'weight',
				'trend',
				'completion',
				'startOn',
				'endOn',
				'reference',
				'evaluatedAt'
			])
		};

		let newFishboneItem = {
			...pick(items[index], [keys(newValues)]),
			...newValues
		};

		if (values.evaluatedAt !== undefined)
			newFishboneItem.evaluatedAt =
				!newFishboneItem.evaluatedAt ||
				!isFunction(newFishboneItem.evaluatedAt?.toISOString)
					? ''
					: newFishboneItem.evaluatedAt.toISOString();

		if (scenario) {
			let scenarioId = scenario.id;
			let item = items[index];
			let scenarioValues =
				item.scenarios.find((s) => s.id === scenarioId) || {};

			scenarioValues = {
				...scenarioValues,
				...pick(values, ['trend', 'completion'])
			};

			console.log('scenarioValues', scenarioValues);

			await optimisticlyUpdateScenario(
				item,
				'FishboneItem',
				scenarioId,
				scenarioValues,
				updateFishboneItemScenario
			);
		} else {
			const result = await updateFishboneItem({
				variables: {
					id: id,
					...newFishboneItem
				},
				update(cache) {
					let fields = {};
					if (values.name !== undefined)
						fields.name = () => values.name;
					if (values.description !== undefined)
						fields.description = () => values.description;
					if (values.weight !== undefined)
						fields.weight = () => values.weight;
					if (values.trend !== undefined)
						fields.trend = () => values.trend;
					if (values.completion !== undefined)
						fields.completion = () => values.completion;
					if (values.startOn !== undefined)
						fields.startOn = () => values.startOn;
					if (values.endOn !== undefined)
						fields.endOn = () => values.endOn;
					if (values.isOpportunity !== undefined)
						fields.isOpportunity = () => values.isOpportunity;
					if (values.reference !== undefined)
						fields.reference = () => values.reference;
					if (values.evaluatedAt !== undefined)
						fields.evaluatedAt = () => newFishboneItem.evaluatedAt;

					if (values.startOn && isObject(values.startOn)) {
						fields.startOn = values.startOn.format('YYYY-MM-DD');
					}

					if (values.endOn && isObject(values.endOn)) {
						fields.endOn = values.endOn.format('YYYY-MM-DD');
					}

					cache.modify({
						id: `FishboneItem:${id}`,
						fields: {
							...fields
						}
					});
				},
				optimisticResponse: {
					updateFishboneItem: {
						__typename: 'FishboneItem',
						id,
						...items[index],
						...newFishboneItem
					}
				}
			});
		}
	};

	const handleDeleteFishboneItem = async (
		fishboneBranchId: string,
		id: string | undefined
	) => {
		console.log('deleteFishboneItem', fishboneBranchId, id);
		if (id == null) {
			return;
		}

		await deleteFishboneItem({
			variables: { id: id },
			update(cache) {
				cache.modify({
					id: `FishboneBranch:${fishboneBranchId}`,
					fields: {
						items(existingFishboneItemsRef, { readField }) {
							return existingFishboneItemsRef.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				deleteFishboneItem: true
			}
		});
	};

	return {
		createFishboneItem: handleCreateFishboneItem,
		updateFishboneItem: handleUpdateFishboneItem,
		deleteFishboneItem: handleDeleteFishboneItem
	};
};
