import React, { useCallback, useEffect } from 'react';

import { Switch, Route } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useHotkeys } from 'react-hotkeys-hook';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { Input } from 'antd';

import { Indicators } from './Indicators/Indicators';
import Loader from 'components/Loading/Loader';
import ProjectModal from 'components/Modal/ProjectModal';
import ProjectNavigation from 'components/navigation/ProjectNavigation';

import SubHeaderPortal from 'layout/header/SubHeaderPortal';

import {
	useCreateRiskCategoryMutation,
	useCreateTagMutation,
	useDeleteRiskCategoryMutation,
	useDeleteTagMutation,
	useGetProjectQuery,
	useUpdateProjectMutation,
	useUpdateRiskCategoryMutation,
	useUpdateTagMutation
} from 'graph/dist/generated';

import Navigator from './Navigator/Navigator';
import { Constraints } from './Constraints/Constraints';
import { Scenarios } from './Scenarios/Scenarios';
import { Fishbone } from './Fishbone/Fishbone';
import { Risks } from './Risks/Risks';

import './Project.sass';
import { isFunction, pickBy, sortBy } from 'lodash';

let lastData: any = null;

const limitFeatures = (props, projectData: any) => {

	return {
		...props,
		features: {
			...props.features,
			indicators: props.features.indicators && (projectData?.getProject?.type === 'pro') || projectData?.getProject?.type === 'risks' || projectData?.getProject?.type === 'stakeholders',
			constraints: props.features.constraints && (projectData?.getProject?.type === 'pro'),
			fishbone: props.features.fishbone && (projectData?.getProject?.type === 'pro'),
			risks: props.features.risks && (projectData?.getProject?.type === 'pro' || projectData?.getProject?.type === 'risks'),
		}
	}
}

export function Project(props: any) {
	useEffect(() => {
		if (props.setExpanded) props.setExpanded(true);
	}, []);

	let history = useHistory();
	const { t } = useTranslation();
	const params: any = useParams();
	const [editedItem, setEditedItem] = React.useState<any | null>(null);
	const [searchTerm, setSearchTerm] = React.useState<any | null>('');
	const [currentScenarioIndex, setCurrentScenarioIndex] = React.useState<
		number | null
	>(null);

	const [updateData] = useUpdateProjectMutation();
	const [createTag] = useCreateTagMutation();
	const [updateTag] = useUpdateTagMutation();
	const [deleteTag] = useDeleteTagMutation();

	const [createRiskCategory] = useCreateRiskCategoryMutation();
	const [updateRiskCategory] = useUpdateRiskCategoryMutation();
	const [deleteRiskCategory] = useDeleteRiskCategoryMutation();

	const { data, refetch, loading } = useGetProjectQuery({
		variables: { id: params?.id }
	});

	let limitedProps = limitFeatures(props, data);

	const _refetch = useCallback(() => {
		setTimeout(() => refetch(), 0);
	}, [refetch]);

	const handleCreateTag = async (name: string, callback: Function) => {
		if (!name) return;

		const result = await createTag({
			variables: {
				name: name,
				projectId: params.id
			}
		});
		await _refetch();
		if (callback) await callback(result.data?.createTag?.id);
		await _refetch();
	};

	// Update project
	const handleUpdateProject = async (id: any, values: any) => {
		console.log('handleUpdateProject', id, values);

		const tags = values.tags;
		delete values.tags;

		const riskCategories = values.riskCategories;
		delete values.riskCategories;

		if (!values.likelihoods || values.likelihoods === 'null') {
			delete values.likelihoods;
		}

		if (!values.impacts || values.impacts === 'null') {
			delete values.impacts;
		}

		console.log('tags', tags);
		console.log('riskCategories', riskCategories);

		// Old tags that disappeared
		const oldTags = projectData?.getProject?.tags.filter(
			(t: any) => !tags?.find((tag: any) => tag.id === t.id)
		);

		// Create or update each tag if needed
		if (tags) {
			await Promise.all([
				...tags.map(async (tag: any) => {
					if (tag.id === undefined) {
						return createTag({
							variables: {
								name: tag.name,
								projectId: id
							}
						});
					} else {
						// Update if name changed
						const originalTag = projectData?.getProject?.tags.find(
							(t: any) => t.id === tag.id
						);
						if (originalTag?.name !== tag.name) {
							return updateTag({
								variables: {
									id: tag.id,
									name: tag.name
								}
							});
						}
					}
				}),
				...oldTags.map(async (tag: any) => {
					return deleteTag({
						variables: {
							id: tag.id
						}
					});
				})
			]);
		}

		// Old categories that disappeared
		const oldCategories = projectData?.getProject?.riskCategories.filter(
			(t: any) => !riskCategories?.find((tag: any) => tag.id === t.id)
		);

		// Create or update each tag if needed
		if (riskCategories) {
			await Promise.all([
				...riskCategories.map(async (riskCategory: any) => {
					if (riskCategory.id === undefined) {
						return createRiskCategory({
							variables: {
								name: riskCategory.name,
								projectId: id
							}
						});
					} else {
						// Update if name changed
						const originalRiskCategory =
							projectData?.getProject?.riskCategories.find(
								(t: any) => t.id === riskCategory.id
							);
						if (originalRiskCategory?.name !== riskCategory.name) {
							return updateRiskCategory({
								variables: {
									id: riskCategory.id,
									name: riskCategory.name
								}
							});
						}
					}
				}),
				...oldCategories.map(async (riskCategory: any) => {
					return deleteRiskCategory({
						variables: {
							id: riskCategory.id
						}
					});
				})
			]);
		}

		if (values.likelihoods) {
			values.likelihoods = values.likelihoods.map((l, index) => ({ id: index + 1, name: l.name }))
		} else {
			delete values.likelihoods
		}

		if (values.impacts) {
			values.impacts = values.impacts.map((l, index) => ({ id: index + 1, name: l.name }))
		} else {
			delete values.impacts
		}

		if (values.fishboneEndOn) values.fishboneEndOn = values.fishboneEndOn.format("YYYY-MM-DD")
		if (values.fishboneStartOn) values.fishboneStartOn = values.fishboneStartOn.format("YYYY-MM-DD")

		values = pickBy(values, (v) => !isFunction(v) && !v?.props);
		console.log('values', values)

		const result = await updateData({
			variables: {
				id: id,
				...values
			}
		});

		await _refetch();
	};

	const onGoNext = (url: string) => {
		history.push(url);
	};

	if (data) {
		lastData = data;
	}

	const projectData = data || lastData;
	const sortedScenarios = sortBy(projectData?.getProject?.scenarios || [], "name");
	const portalContent = (
		<>
			<div>
				<div className="Title">
					{projectData?.getProject?.name
						? projectData?.getProject?.name
						: projectData?.getProject
							? 'No name'
							: ''}
				</div>
				<div className="SubTitle">
					<small>
						{projectData?.getProject?.description
							? projectData?.getProject?.description
							: projectData?.getProject
								? null
								: null}
					</small>
				</div>
			</div>

			<FontAwesomeIcon
				style={{
					marginLeft: '0.5rem',
					fontSize: '1.2rem',
					cursor: 'pointer'
				}}
				icon={faEdit}
				onClick={() => setEditedItem(projectData?.getProject)}
			/>
		</>
	);

	const currentScenario =
		currentScenarioIndex !== null
			? sortedScenarios[currentScenarioIndex]
			: null;

	const handleGoToPreviousScenario = () => {
		if (
			currentScenarioIndex === 0 &&
			sortedScenarios?.length > 0
		) {
			setCurrentScenarioIndex(null);
		} else if (currentScenarioIndex > 0) {
			setCurrentScenarioIndex(currentScenarioIndex - 1);
		}
	};

	const handleGoToNextScenario = () => {
		if (
			currentScenarioIndex === null &&
			sortedScenarios?.length > 0
		) {
			setCurrentScenarioIndex(0);
		} else if (currentScenarioIndex === null) {
		} else if (
			currentScenarioIndex <
			sortedScenarios?.length - 1
		) {
			setCurrentScenarioIndex(currentScenarioIndex + 1);
		}
	};

	// Change scenario with arrow keys
	useHotkeys('ArrowRight', handleGoToNextScenario);
	useHotkeys('ArrowLeft', handleGoToPreviousScenario);

	const handleGoToScenario = (index) => {
		setCurrentScenarioIndex(index);
	};

	const searchBar = (
		<Input
			onChange={(e) => {
				setSearchTerm(e.target.value);
			}}
			value={searchTerm}
			style={{ marginLeft: 'auto', marginRight: '1em' }}
			placeholder="Search"
			className="SearchInput"
		></Input>
	);


	return (
		<div
			className={['Project', !limitedProps.expanded ? 'visible' : ''].join(' ')}
		>
			<SubHeaderPortal>
				<ProjectNavigation
					loading={loading && !projectData?.getProject?.name}
					{...limitedProps}
					onExpand={() => {
						limitedProps.setExpanded(true);
					}}
					onShrink={() => {
						limitedProps.setExpanded(false);
					}}
					expanded={limitedProps.expanded}
					setExpanded={limitedProps.setExpanded}
					project={projectData?.getProject}
					scenario={currentScenario}
					scenarioIndex={currentScenarioIndex}
					scenarioCount={sortedScenarios?.length}
					onGoToPreviousScenario={handleGoToPreviousScenario}
					onGoToNextScenario={handleGoToNextScenario}
					onGoToScenario={handleGoToScenario}

					onUpdate={handleUpdateProject}
					{...projectData?.getProject}

					displayConstraints={limitedProps.features?.constraints}
					displayIndicators={limitedProps.features?.indicators}
					displayFishbone={limitedProps.features?.fishbone}
					displayRisks={limitedProps.features?.risks}
					displayAdvancedScenarios={limitedProps.features?.displayAdvancedScenarios}
					pushModal={limitedProps.pushModal}
					popModal={limitedProps.popModal}

					refetch={_refetch}
				></ProjectNavigation>
			</SubHeaderPortal>

			{loading ? (
				<Loader></Loader>
			) : (
				<>
					<Switch>
						<Route key="navigator" exact path="/projects/:id">
							<Navigator
								{...projectData?.getProject}
								refetch={_refetch}
								{...limitedProps}
								onCreateTag={handleCreateTag}
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								expanded={limitedProps.expanded}
								scenario={currentScenario}
								scenarios={sortedScenarios}
								displayConstraints={limitedProps.features?.constraints}
								displayIndicators={limitedProps.features?.indicators}
								displayRisks={limitedProps.features?.risks}
								portalContent={
									<>
										{portalContent}
										{searchBar}
									</>
								}
							/>
						</Route>

						<Route
							key="project"
							path={`/projects/:id/${t('pages.indicators.url')}`}
							render={() => (
								<Indicators
									onGoNext={onGoNext}
									refetch={_refetch}
									{...limitedProps}
									onEditProject={() =>
										setEditedItem(projectData?.getProject)
									}
									expanded={limitedProps.expanded}
									scenario={currentScenario}
									{...projectData?.getProject}
									scenarios={sortedScenarios}
									portalContent={portalContent}
								></Indicators>
							)}
						></Route>

						<Route
							key="project"
							path={`/projects/:id/${t('pages.constraints.url')}`}
							render={() => (
								<Constraints
									onGoNext={onGoNext}
									refetch={_refetch}
									{...limitedProps}
									onEditProject={() =>
										setEditedItem(projectData?.getProject)
									}
									expanded={limitedProps.expanded}
									scenario={currentScenario}
									{...projectData?.getProject}
									scenarios={sortedScenarios}
									portalContent={portalContent}
								></Constraints>
							)}
						></Route>

						<Route
							key="project"
							path="/projects/:id/scenarios"
							render={() => (
								<Scenarios
									onGoNext={onGoNext}
									refetch={_refetch}
									onGoToScenario={handleGoToScenario}
									{...limitedProps}
									scenario={currentScenario}
									scenarioIndex={currentScenarioIndex}
									onGoToPreviousScenario={
										handleGoToPreviousScenario
									}
									onGoToNextScenario={handleGoToNextScenario}
									onEditProject={() =>
										setEditedItem(projectData?.getProject)
									}
									expanded={limitedProps.expanded}
									{...projectData?.getProject}
									scenarios={sortedScenarios}
									portalContent={portalContent}
								></Scenarios>
							)}
						></Route>

						<Route
							key="project"
							path="/projects/:id/risks/:view?"
							render={() => (
								<Risks
									onGoNext={onGoNext}
									refetch={_refetch}
									{...limitedProps}
									onUpdateProject={handleUpdateProject}
									scenario={currentScenario}
									scenarioIndex={currentScenarioIndex}
									setSearchTerm={setSearchTerm}
									searchTerm={searchTerm}
									onGoToPreviousScenario={
										handleGoToPreviousScenario
									}
									onGoToNextScenario={handleGoToNextScenario}
									onEditProject={() =>
										setEditedItem(projectData?.getProject)
									}
									expanded={limitedProps.expanded}
									{...projectData?.getProject}
									scenarios={sortedScenarios}
									portalContent={portalContent}
								></Risks>
							)}
						></Route>
						<Route
							key="project"
							path="/projects/:id/opportunities/:view?"
							render={() => (
								<Risks
									onGoNext={onGoNext}
									refetch={_refetch}
									{...limitedProps}
									onUpdateProject={handleUpdateProject}
									scenario={currentScenario}
									scenarioIndex={currentScenarioIndex}
									setSearchTerm={setSearchTerm}
									searchTerm={searchTerm}
									onGoToPreviousScenario={
										handleGoToPreviousScenario
									}
									onGoToNextScenario={handleGoToNextScenario}
									onEditProject={() =>
										setEditedItem(projectData?.getProject)
									}
									expanded={limitedProps.expanded}
									{...projectData?.getProject}
									scenarios={sortedScenarios}
									portalContent={portalContent}
								></Risks>
							)}
						></Route>

						<Route
							key="project"
							path="/projects/:id/fishbone"
							render={() => (
								<Fishbone
									onGoNext={onGoNext}
									refetch={_refetch}
									{...limitedProps}
									onUpdateProject={handleUpdateProject}
									scenario={currentScenario}
									scenarioIndex={currentScenarioIndex}
									onGoToPreviousScenario={
										handleGoToPreviousScenario
									}
									onGoToNextScenario={handleGoToNextScenario}
									onEditProject={() =>
										setEditedItem(projectData?.getProject)
									}
									expanded={limitedProps.expanded}
									{...projectData?.getProject}
									scenarios={sortedScenarios}
									portalContent={portalContent}
								></Fishbone>
							)}
						></Route>
					</Switch>
					{limitedProps.children}
				</>
			)}
			{editedItem && (
				<ProjectModal
					onHide={() => {
						setEditedItem(null);
					}}
					onSave={(values: Object) => {
						handleUpdateProject(editedItem.id, values);
						setEditedItem(null);
					}}
					id={editedItem?.id}
					key={editedItem?.id}
					values={{
						name: editedItem?.name,
						reference: editedItem?.reference,
						description: editedItem?.description,
						descriptionLong: editedItem?.descriptionLong,
						likelihoods: editedItem?.likelihoods,
						impacts: editedItem?.impacts,
						tags: editedItem?.tags,
						riskCategories: editedItem?.riskCategories,
						type: editedItem?.type,
					}}
					authorizedTypes={props.authorizedProjectTypes}
				></ProjectModal>
			)}
		</div>
	);
}
