import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
//import { useAuth0 } from '../../react-auth0-spa';

export const PrivateRoute: React.FC<RouteProps> = ({
	component: Component,
	path,
	...rest
}) => {
	const render: RouteProps['render'] = (props) => {
		if (Component != null) {
			return <Component {...props} />;
		}

		return null;
	};

	return <Route path={path} render={render} {...rest} />;
};
