import { isArray, keyBy } from 'lodash';
import {
	colorToPercent,
	getIndividualRiskScore,
	getRiskScore
} from 'utils/ranking';

export const getItemAlteredByScenario = (item, scenario) => {
	if (!scenario?.id) return item?.o || item;

	//console.group('getItemAlteredByScenario');

	//console.log('item', item, scenario);

	if (item.scenarios && isArray(item.scenarios)) {
		const indexedScenarios = isArray(item.scenarios)
			? keyBy(item.scenarios, 'scenarioId')
			: {};
		let attributes = indexedScenarios[scenario?.id]
			? indexedScenarios[scenario?.id]
			: {};

		//console.log('attributes', attributes);

		let impact =
			attributes?.impact !== undefined
				? attributes?.impact
				: item?.impact;
		if (item.__typename === 'Risk') {
			impact = attributes?.impact || item?.impact;
		}

		//console.groupEnd('getItemAlteredByScenario');
		return {
			...item,
			x: attributes.x !== undefined ? attributes.x : item.x,
			y: attributes.y !== undefined ? attributes.y : item.y,
			trend:
				attributes.trend !== undefined ? attributes.trend : item.trend,
			likelihood: attributes.likelihood || item.likelihood,
			impact: impact,
			completion: attributes.completion || item.completion,
			o: {
				...(item.o || item)
			}
		};
	}
	if (typeof item.scenarios === 'string') {
		let sc = item.scenarios ? JSON.parse(item.scenarios) : {};
		let attributes = sc && sc[scenario?.id] ? sc[scenario?.id] : {};

		//console.groupEnd('getItemAlteredByScenario');

		return {
			...item,
			x: attributes.x !== undefined ? attributes.x : item.x,
			y: attributes.y !== undefined ? attributes.y : item.y,
			trend:
				attributes.trend !== undefined ? attributes.trend : item.trend,
			impact:
				attributes?.impact !== undefined
					? attributes?.impact
					: item?.impact,
			o: {
				...item
			}
		};
	}
};

export const vulnerabilityConstraints = function (constraints) {
	//console.log('-------- vulnerability', items);

	if (!constraints || constraints.length == 0) {
		return null;
	}

	var total_weight = 0;
	var total = 0;

	for (var i = 0; i < constraints.length; i++) {
		if (constraints[i].weight !== 0)
			total_weight += constraints[i].weight || 1;

		if (constraints[i].weight !== 0)
			total +=
				colorToPercent(constraints[i].trend) *
				(constraints[i].weight || 1);
	}

	let currentPercentage = total_weight != 0 ? total / total_weight : 50;

	return currentPercentage;
};

export const vulnerabilityIndicators = function (indicators) {
	//console.log('-------- vulnerability', indicators)

	if (!indicators || indicators.length == 0) {
		return null;
	}

	var total_weight = 0;
	var total = 0;

	for (var i = 0; i < indicators.length; i++) {
		if (indicators[i].weight !== 0)
			total_weight += indicators[i].weight || 1;

		if (indicators[i].weight !== 0)
			total +=
				colorToPercent(indicators[i].trend) *
				(indicators[i].weight || 1);
	}

	let currentPercentage = total_weight != 0 ? total / total_weight : 50;

	return currentPercentage;
};

export const isItemInactive = (item) => {
	if (!item) return false;

	const distanceFromCenter = Math.sqrt(
		Math.pow(item.x, 2) + Math.pow(item.y, 2)
	);
	const isOutside = distanceFromCenter > 100;

	return isOutside;
};

export const isItemNeutral = (item) => {
	if (!item) return false;

	if (item.y <= 5 && item.y >= -5) return true;

	return false;
};

export const isRiskOrOpportunityWorse = (risk) => {
	if (!risk) return false;
	if (!risk.o) return false;

	return risk.isOpportunity
		? getIndividualRiskScore(risk) < getIndividualRiskScore(risk.o)
		: getIndividualRiskScore(risk) > getIndividualRiskScore(risk.o);
};

export const isRiskOrOpportunityBetter = (risk) => {
	if (!risk) return false;
	if (!risk.o) return false;

	return risk.isOpportunity
		? getIndividualRiskScore(risk) > getIndividualRiskScore(risk.o)
		: getIndividualRiskScore(risk) < getIndividualRiskScore(risk.o);
};
