import { Popover, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

export const FishboneTail = (props: any) => {
	const insideMargin = 0.4 * props.width;
	const { t } = useTranslation();

	const points = [
		[0, 0],
		[props.width, props.height / 2],
		[0, props.height],
		[insideMargin, props.height / 2],
		[0, 0]
	];

	return (
		<g>
			<polygon
				strokeWidth={1}
				stroke="#A6B9C8"
				fill="#FFFFFF11"
				points={points.map((p) => p.join(',')).join(' ')}
			></polygon>

			<text
				fill="white"
				dy={props.height / 2 + 3}
				dx={-1.35 * insideMargin + props.width}
			>
				{t("models.scenario.baseline")}
			</text>
			<g
				className="add"
				onClick={props.onCreateBranch}
				transform={'translate(' + props.width / 6 + ' 0)'}
			>
				<circle
					cx={props.width}
					cy={props.height / 2}
					r={props.width / 8}
					fill={'#CCCCCC'}
				></circle>
				<text
					fill="white"
					fontSize={props.width / 4}
					dx={props.width - props.width / 14}
					dy={props.height / 2 + props.width / 11}
				>
					+
				</text>
			</g>
		</g>
	);
};
