// @ts-nocheck
import React, { Component, useEffect, useState } from 'react';

import UIContext from './UIContext';
import { set } from 'lodash';

const defaultPreferences = {
	global: {},
	pages: {
		navigator: {
			links: false
		},
		risks: {
			sortOrder: 'ascend',
			sortBy: 'id',
			columns: {
				evaluatedAt: true,
			}
		},
		indicators: {
			sortOrder: 'ascend',
			sortBy: 'id'
		},
		constraints: {
			sortOrder: 'ascend',
			sortBy: 'id'
		},
		fishbone: {

		}
	}
};

const UIProvider = ({ children }) => {

	const [ready, setReady] = useState(false);

	const [preferences, setPreferences] = useState({
		...defaultPreferences
	});

	const [selection, setSelection] = useState(null);

	useEffect(() => {
		const storedPreferences = localStorage.getItem('gerositus.preferences');
		let parsedPreferences = {}
		try {
			parsedPreferences = storedPreferences ? JSON.parse(storedPreferences) : {};
		} catch (e) {
			console.error('Error parsing preferences', e);
			localStorage.removeItem('gerositus.preferences');
		}

		setPreferences(
			{
				...defaultPreferences,
				...parsedPreferences
			}
		);
		setReady(true);
	}, []);

	const setSpecificPreferences = async (type, preference, value) => {
		console.log('setSpecificPreferences', type, preference, value);
		const newPreferences = set(preferences, `${type}.${preference}`, value);
		setPreferences({ ...newPreferences });
		localStorage.setItem('gerositus.preferences', JSON.stringify(newPreferences));
	};

	const select = async (obj, callback) => {
		await setSelection(obj);
		callback && callback();
	};

	const deselect = async (callback) => {
		await setSelection(null);
		callback && callback();
	};

	return <UIContext.Provider value={{
		selection,
		select: setSelection,
		deselect: setSelection,
		preferences,
		setPreferences: setSpecificPreferences
	}}>
		{ready ? children : null}
	</UIContext.Provider >


}

export default UIProvider;