import React, { useCallback } from 'react';

import { useHistory } from 'react-router';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';

import { findIndex, pick, filter, sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import AddButton from 'components/action/AddButton';
import ConstraintEditionModal from 'components/Modal/ConstraintEditionModal';
import NavigationButton from 'components/action/NavigationButton';

import HeaderPortal from 'layout/header/HeaderPortal';
import { ConfirmModal } from 'components/Modal/ConfirmModal';

// Help
import TutorialPopover from 'components/Help/TutorialPopover';
import { getNextStep, getInitialStep } from 'help/constraints';

import { MiniSlider } from 'components/controls/MiniSlider';
import {
	getItemAlteredByScenario,
	vulnerabilityConstraints
} from 'utils/rules';
import HorizontalVulnerabilityScale from 'components/Radar/HorizontalVulnerabilityScale';

import Table from 'components/Table';
import { Button, Tooltip } from 'antd';
import Weight from 'components/controls/Weight';

import './Constraints.sass';
import { useConstraintController } from 'hooks/useConstraintController';
import dayjs from 'dayjs';
import { style } from 'd3';

const constraintsLimit = 60;

export const Constraints = (props: any) => {
	const [editedItem, setEditedItem] = React.useState<any | null>(null);
	let history = useHistory();
	const params: any = useParams();

	const { createConstraint, updateConstraint, deleteConstraint } = useConstraintController();

	const { t } = useTranslation();

	// Help
	const [visibleHelpStep, setVisibleHelpStep] = React.useState<any | null>(
		null
	);
	const closeHelp = () => setVisibleHelpStep(null);
	const initialStep = getInitialStep(props.constraints?.length);
	const nextStep = getNextStep(visibleHelpStep, props.constraints?.length);
	const onNext = () =>
		setVisibleHelpStep(nextStep, props.constraints?.length);
	const hasNext = !!nextStep;

	const otherScenarios = filter(
		props.scenarios || [],
		(sc) => sc.id !== props.scenario?.id
	).concat([{ id: null }]);
	let alteredConstraints = sortBy(
		props.constraints.map((c, index) => ({
			...getItemAlteredByScenario(c, props.scenario),
			index: index
		})
		),
		'name'
	);
	let score = vulnerabilityConstraints(alteredConstraints);

	let vulnerabilities = (props.scenarios || []).map((sc) => {
		return {
			id: sc.id,
			name: sc.name,
			vulnerability: vulnerabilityConstraints(
				props.constraints.map((c) => getItemAlteredByScenario(c, sc))
			)
		};
	});

	vulnerabilities.push({
		id: null,
		vulnerability: vulnerabilityConstraints(props.constraints)
	});

	// Create
	const handleCreateConstraint = async () => {
		props.pushModal(
			<ConstraintEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) => {
					let variables = {
						projectId: params.id,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						description: values.description || '',
						reference: values.reference || '',
						evaluatedAt: values.evaluatedAt || ''
					};

					let result = await createConstraint(params.id, variables);

					props.popModal();
				}}
				id={false}
				values={{
					name: '',
					weight: 1,
					description: '',
					reference: '',
					evaluatedAt: null,
				}}
			></ConstraintEditionModal>
		);
	};


	// Update
	const handleUpdateConstraint = async (id: string, values: Object) => {
		updateConstraint(id, props.constraints || [], props.scenario, values);
	};

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.constraint.messages.delete_confirmation')}
				t={t}
				onConfirm={() => deleteConstraint(params.id, id)}
				message={t(
					'models.constraint.messages.delete_confirmation_explanation'
				)}
			></ConfirmModal>
		);
	};

	const columns = [
		{
			title: '',
			dataIndex: 'edit',
			key: 'edit',
			width: 50,
			render: (text, record) => (
				<Tooltip title={props.scenario ? null : 'Edit'}>
					<Button
						shape="circle"
						disabled={props.scenario ? true : false}
						style={{ opacity: props.scenario ? 0.2 : 1 }}
						onClick={
							props.disabled
								? () => { }
								: () => {
									setEditedItem(record);
								}
						}
					>
						<FontAwesomeIcon
							icon={faEdit}
							style={{
								cursor: props.disabled
									? 'not-allowed'
									: 'initial',
								opacity: props.disabled ? 0.5 : 1
							}}
						/>
					</Button>
				</Tooltip>
			)
		},
		{
			title: '#',
			dataIndex: 'id',
			key: 'id',
			defaultSortOrder: 'ascend',
			width: 60,
			sortable: (a, b) => {
				const scoreA = (a.reference || ("I" + (a.index + 1))).toLocaleLowerCase()
				const scoreB = (b.reference || ("I" + (b.index + 1))).toLocaleLowerCase()

				if (scoreA < scoreB) return -1;
				if (scoreA > scoreB) return 1;
				return 0
			},
			render: (text, record) => {

				return record.reference || ("I" + ((record.index + 1 + "").padStart(2, '0')))
			}
		},
		{
			title: t("models.constraint.fields.evaluated_at"),
			dataIndex: 'evaluatedAt',
			key: 'evaluatedAt',
			className: 'nowrap',
			width: 140,
			sortable: (a, b) => {
				if (!b.evaluatedAt) return -1
				if (!a.evaluatedAt) return 1
				if (a.evaluatedAt && b.evaluatedAt && a.evaluatedAt < b.evaluatedAt) return -1;
				if (a.evaluatedAt && b.evaluatedAt > b.evaluatedAt) return 1;
				return 0
			},
			render: (text, record, index) => {

				return <Tooltip title={record.evaluatedAt ? dayjs(record.evaluatedAt).format('DD/MM/YYYY HH[h]mm') : ''}>
					<div>
						{record.evaluatedAt ? dayjs(record.evaluatedAt).format('DD/MM/YYYY') : ''}
					</div>
				</Tooltip>
				return record.evaluatedAt ? dayjs(record.evaluatedAt).format('DD/MM/YYYY') : ''
			}
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			className: 'w-50',
			sortable: true,
			ellipsis: true,
			render: (text, record) => {
				return (
					<div className="name w-100">
						{record.name}
						{record.description ? (
							<div className="description">
								{record.description}
							</div>
						) : (
							''
						)}
					</div>
				);
			}
		},
		{
			title: 'Status',
			dataIndex: 'trend',
			key: 'trend',
			width: 200,
			sortable: (a, b) => {
				const scoreA = parseInt(a.trend)
				const scoreB = parseInt(b.trend)

				if (scoreA < scoreB) return -1;
				if (scoreA > scoreB) return 1;
				return 0
			},
			render: (text, item) => {
				return (
					<MiniSlider
						className="ml-auto mr-2"
						key={item.id + '-' + props.scenario?.id}
						value={item.trend}
						min={0}
						max={100}
						style={{ width: '100%', flexShrink: 0 }}
						onChange={(val) =>
							handleUpdateConstraint(item.id, { trend: val })
						}
						additionalMarkers={otherScenarios.map((sc) => {
							return {
								id: sc.id,
								name: sc.name || t("models.scenario.baseline"),
								value: getItemAlteredByScenario(item, sc).trend
							};
						})}
					></MiniSlider>
				);
			}
		},
		{
			title: 'Weight',
			dataIndex: 'weight',
			key: 'weight',
			sortable: true,
			width: 100,
			render: (text, record) => {
				return (
					<Weight
						style={{ marginRight: '0.5rem' }}
						onClick={(values: any) => {
							let newWeight = record.weight
								? record.weight + 1
								: 1;
							if (newWeight > 3) newWeight = 0;
							console.log('onCycleWeight', record, newWeight);
							handleUpdateConstraint(record.id, {
								...record,
								weight: newWeight
							});
						}}
						disabled={!!props.scenario}
					>
						{record.weight}
					</Weight>
				);
			}
		}
	];

	return (
		<div className="Constraints pb-3">
			<HeaderPortal>
				{props.portalContent}{' '}
				<FontAwesomeIcon
					style={{
						marginLeft: 'auto',
						marginRight: '0.5rem',
						cursor: 'pointer'
					}}
					icon={faInfoCircle}
					onClick={() => setVisibleHelpStep(initialStep)}
				/>
			</HeaderPortal>

			<h2>{t('pages.constraints.title')}</h2>

			<h3 className="mb-4">{t('pages.constraints.subtitle')}</h3>

			<TutorialPopover
				onClose={closeHelp}
				placement={'top'}
				style={{ maxWidth: '400px' }}
				visible={visibleHelpStep == 2}
				body={
					'This gauge is a weighted consolidation of your constraints assessments, to a variable extent the result of your work on stakeholders'
				}
				onNext={onNext}
				hasNext={hasNext}
			>
				<HorizontalVulnerabilityScale
					t={t}
					vulnerabilities={vulnerabilities}
					scenarios={props.scenarios}
					scenario={props.scenario}
				></HorizontalVulnerabilityScale>
			</TutorialPopover>

			<div className="mb-3 mt-4 w-100" style={{ maxWidth: '1200px' }}>
				<Table
					pagination={false}
					dataSource={alteredConstraints}
					columns={columns}
					size="small"
				/>
			</div>

			{editedItem && (
				<ConstraintEditionModal
					onHide={() => {
						setEditedItem(null);
					}}
					onDelete={() => {
						askDeleteConfirmation(editedItem.id);
						setEditedItem(null);
					}}
					onSave={(values: Object) => {
						handleUpdateConstraint(editedItem.id, values);
						setEditedItem(null);
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						weight: editedItem?.weight || 1,
						description: editedItem?.description || '',
						reference: editedItem?.reference,
						evaluatedAt: editedItem?.evaluatedAt ? dayjs(editedItem?.evaluatedAt) : null,
					}}
				></ConstraintEditionModal>
			)}

			<div className="w-100 d-flex justify-content-center align-items-center">

				<div
					className="w-100 d-flex justify-content-center align-items-center"
				>
					{props.constraints?.length < constraintsLimit && (
						<AddButton
							popover={
								<TutorialPopover
									onClose={closeHelp}
									placement={'top'}
									style={{ maxWidth: '400px' }}
									visible={visibleHelpStep == 1}
									body={<>Add constraints.</>}
									onNext={onNext}
									hasNext={hasNext}
								></TutorialPopover>
							}
							onClick={handleCreateConstraint}
						></AddButton>
					)}
				</div>
			</div>
		</div>
	);
};
