import { Popover, Tooltip } from 'antd';
import React from 'react';

export const FishboneBody = (props: any) => {
	return (
		<g transform={'translate(' + props.leftOffset + ')'}>
			<line
				strokeWidth={2}
				x1={0}
				x2={props.width}
				y1={props.height / 2}
				y2={props.height / 2}
				stroke="#A6B9C8"
			></line>

			{props.children.map((c, index) => {
				let chunkSize = (props.width * 0.9) / props.children.length;

				return React.cloneElement(c, {
					position: index % 2 ? 'top' : 'bottom',
					width: chunkSize * 0.8,
					height: props.height / 2,
					leftOffset: index * chunkSize,
					debug: index == 1
				});
			})}
		</g>
	);
};
