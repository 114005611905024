import { ApolloLink } from '@apollo/client';
import { createAppSyncSubscriptionWebsocketLink } from './appSyncSubscriptionWebSocketLink';
import { createAppSyncHttpLink } from './appSyncHttpLink';
import { getMainDefinition } from '@apollo/client/utilities';

export const createAppSyncHybridLink = async ({ appSyncApiUrl, getJwtToken }) =>
	ApolloLink.split(
		isSubscriptionOperation,
		await createAppSyncSubscriptionWebsocketLink({
			appSyncApiUrl,
			getJwtToken
		}),
		createAppSyncHttpLink({ appSyncApiUrl, getJwtToken })
	);

const isSubscriptionOperation = ({ query }) => {
	const { kind, operation } = getMainDefinition(query);
	return kind === 'OperationDefinition' && operation === 'subscription';
};
