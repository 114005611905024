import React from 'react';

import Weight from "components/controls/Weight";
import { useTranslation } from "react-i18next";

import './OptionsTab.sass';

export const OptionsTab = (props: any) => {

    const { t } = useTranslation();

    const isLastEvaluationVisible = props.preferences?.evaluatedAt !== undefined ? props.preferences?.evaluatedAt : true
    const isRiskCategoryVisible = props.preferences?.riskCategory !== undefined ? props.preferences?.riskCategory : true
    const isStakeholdersVisible = props.preferences?.stakeholders || false
    const isMatchingROVisible = props.preferences?.matchingRO || false

    return (
        <div className="OptionsTab">

            {<>
                <div
                    className="text-left mt-2"
                    style={{ marginBottom: '0.5rem' }}
                >
                    {t('pages.risks.tabs.filters.displayed_columns')}
                </div>
                <div className="columnsList">
                    <div className="d-flex mb-05">
                        <div style={{ width: "95%" }}>
                            {t('models.risk.fields.evaluated_at')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'evaluatedAt',
                                    !isLastEvaluationVisible
                                );
                            }}
                        >
                            {isLastEvaluationVisible ? 1 : 0}
                        </Weight>
                    </div>
                    <div className="d-flex mb-05">
                        <div style={{ width: "95%" }}>
                            {t('models.risk.fields.risk_category')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'riskCategory',
                                    !isRiskCategoryVisible
                                );
                            }}
                        >
                            {isRiskCategoryVisible ? 1 : 0}
                        </Weight>
                    </div>
                    <div className="d-flex mb-05">
                        <div style={{ width: "95%" }}>
                            {t('models.item.name_plural')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'stakeholders',
                                    !isStakeholdersVisible
                                );
                            }}
                        >
                            {isStakeholdersVisible ? 1 : 0}
                        </Weight>
                    </div>
                    <div className="d-flex mb-05">
                        <div style={{ width: "95%" }}>
                            {t('models.risk.fields.matching_risks')}
                            /
                            {t('models.risk.fields.matching_opportunity')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'matchingRO',
                                    !isMatchingROVisible
                                );
                            }}
                        >
                            {isMatchingROVisible ? 1 : 0}
                        </Weight>
                    </div>

                </div></>}


        </div>
    );
}