// @ts-nocheck
import { createContext } from 'react';

export default createContext({
	selection: null,
	select: (object) => { },
	deselect: (object) => { },
	preferences: {},
	setPreferences: () => { },
});
