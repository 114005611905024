import React, { Component } from 'react';

import { find, keyBy, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Button, Tag } from 'antd';
import { getScoreColor } from 'utils/ranking';
import Weight from 'components/controls/Weight';
import { useStakeholderController } from 'hooks/useStakeholderController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';

export const RisksTab = (props: any) => {
	const { t } = useTranslation();

	const { createRiskImpact, updateRiskImpactStrength, deleteRiskImpact } = useStakeholderController();

	const indexedRisks = keyBy((props.risks || []), 'id');
	const isImpactingRisk = (riskId: string) => {
		if (
			indexedRisks[riskId] &&
			props.selection?.impactedRisks
				?.map((ii: any) => ii && ii.riskId)
				.includes(riskId)
		)
			return true;
		return false;
	};

	const getImpactingRiskDetails = (riskId: string) => {
		return find(
			props.selection?.impactedRisks || [],
			(ii: any) => ii && ii.riskId == riskId
		);
	};

	return (
		<>
			<div className="d-flex flex-column flex-grow">
				<div className="d-flex flex-column flex-grow mt-4">
					<div className="text-left mb-05 d-flex align-items-center">
						{t('models.item.fields.risks.name')}

						<Button shape="circle" className="mini discreet ml-auto" onDoubleClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}} onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							props.setPreferences(
								'pages',
								'navigator.risks',
								!props.areRisksVisible
							);
						}}>
							<FontAwesomeIcon
								icon={!props.areRisksVisible ? faChevronDown : faChevronUp}
							></FontAwesomeIcon>
						</Button>

					</div>
					{props.areRisksVisible && <div className="text-left">
						{props.risks && props.risks.length ? (
							sortBy(props.risks || [], 'name').filter(r => !r.isOpportunity).map((i: any) => {
								const impactedRisk = getImpactingRiskDetails(
									i.id
								);

								return (
									<Tag
										key={i.id}
										onClick={async () => {
											if (props.scenario) return true;

											if (
												!impactedRisk ||
												!impactedRisk.id
											) {
												let createdImpact =
													await createRiskImpact(
														props.selection?.id,
														i.id
													);

												props.select({
													...props.selection,
													impactedRisks: [
														...(props.selection
															.impactedRisks ||
															[]),
														createdImpact
													]
												});
											} else if (
												impactedRisk.strength < 3
											) {
												updateRiskImpactStrength(
													impactedRisk,
													impactedRisk.strength + 1
												);

												props.select({
													...props.selection,
													impactedRisks:
														props.selection.impactedRisks.map(
															(r) => ({
																...r,
																strength:
																	r.id ==
																		impactedRisk.id
																		? r.strength +
																		1
																		: r.strength
															})
														)
												});
											} else {
												deleteRiskImpact(
													impactedRisk.id,
													props.selection?.id,
												);

												props.select({
													...props.selection,
													impactedRisks:
														props.selection.impactedRisks.filter(
															(r) =>
																r.riskId !=
																impactedRisk.riskId
														)
												});
											}
										}}
										className={[
											'ImpactedRisk tag',
											isImpactingRisk(i.id)
												? 'selected'
												: 'unselected'
										].join(' ')}
									>
										<div
											className={'Trend v' + i.trend}
											style={
												i.trend == '50'
													? {}
													: {
														background:
															getScoreColor(
																i.trend
															)
													}
											}
										></div>
										{i.name}

										{impactedRisk ? (
											<Weight onClick={() => { }}>
												{impactedRisk.strength}
											</Weight>
										) : null}
									</Tag>
								);
							})
						) : (
							<div
								className="text-center"
								style={{ color: 'rgba(46, 160, 223, 0.3)' }}
							>
								{t('models.risk.messages.no_x')}
							</div>
						)}
					</div>}

					<div className="text-left mb-05 mt-4 d-flex align-items-center">
						{t('models.item.fields.opportunities.name')}

						<Button shape="circle" className="mini discreet ml-auto" onDoubleClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}} onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							props.setPreferences(
								'pages',
								'navigator.opportunities',
								!props.areOpportunitiesVisible
							);
						}}>
							<FontAwesomeIcon
								icon={!props.areOpportunitiesVisible ? faChevronDown : faChevronUp}
							></FontAwesomeIcon>
						</Button>
					</div>
					{props.areOpportunitiesVisible && <div className="text-left">
						{props.risks && props.risks.length ? (
							sortBy(props.risks || [], 'name').filter(r => r.isOpportunity).map((i: any) => {
								const impactedRisk = getImpactingRiskDetails(
									i.id
								);

								return (
									<Tag
										key={i.id}
										onClick={async () => {
											if (props.scenario) return true;

											if (
												!impactedRisk ||
												!impactedRisk.id
											) {
												let createdImpact =
													await createRiskImpact(
														props.selection?.id,
														i.id
													);

												props.select({
													...props.selection,
													impactedRisks: [
														...(props.selection
															.impactedRisks ||
															[]),
														createdImpact
													]
												});
											} else if (
												impactedRisk.strength < 3
											) {
												updateRiskImpactStrength(
													impactedRisk,
													impactedRisk.strength + 1
												);

												props.select({
													...props.selection,
													impactedRisks:
														props.selection.impactedRisks.map(
															(r) => ({
																...r,
																strength:
																	r.id ==
																		impactedRisk.id
																		? r.strength +
																		1
																		: r.strength
															})
														)
												});
											} else {
												deleteRiskImpact(
													impactedRisk.id,
													props.selection?.id,
												);

												props.select({
													...props.selection,
													impactedRisks:
														props.selection.impactedRisks.filter(
															(r) =>
																r.riskId !=
																impactedRisk.riskId
														)
												});
											}
										}}
										className={[
											'ImpactedRisk tag',
											isImpactingRisk(i.id)
												? 'selected'
												: 'unselected'
										].join(' ')}
									>
										<div
											className={'Trend v' + i.trend}
											style={
												i.trend == '50'
													? {}
													: {
														background:
															getScoreColor(
																i.trend
															)
													}
											}
										></div>
										{i.name}

										{impactedRisk ? (
											<Weight onClick={() => { }}>
												{impactedRisk.strength}
											</Weight>
										) : null}
									</Tag>
								);
							})
						) : (
							<div
								className="text-center"
								style={{ color: 'rgba(46, 160, 223, 0.3)' }}
							>
								{t('models.risk.messages.no_x')}
							</div>
						)}
					</div>}
				</div>
			</div>
		</>
	);
};

export default RisksTab;
