import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'antd';

import { keyBy, keys, maxBy, values } from 'lodash';
import { isRiskOrOpportunityBetter, isRiskOrOpportunityWorse } from 'utils/rules';

const circlePlacementDx = (d, similarRisks) => {

	// Répartition des points en fonction de leur index en cercle autour du point d'origine 0, 0
	const similarCount = similarRisks[d.id].similarCount;
	const index = similarRisks[d.id].index;

	if (similarCount == 0) return 0;

	const angle = 2 * Math.PI * index / (similarCount + 1);
	const dx = Math.cos(angle);

	return dx
}

const circlePlacementDy = (d, similarRisks) => {

	// Répartition des points en fonction de leur index en cercle autour du point d'origine 0, 0
	const similarCount = similarRisks[d.id].similarCount;
	const index = similarRisks[d.id].index;

	if (similarCount == 0) return 0;

	const angle = 2 * Math.PI * index / (similarCount + 1);
	const dy = Math.sin(angle);

	return dy;
}

const circleDx = (d, similarRisks, x, space) => {
	if (Math.abs(circlePlacementDx(d, similarRisks) * 10) < 0.1) return 0

	if (circlePlacementDx(d, similarRisks) > 0) return space(similarRisks[d.id].similarCount) * x(1) * circlePlacementDx(d, similarRisks) + 3
	if (circlePlacementDx(d, similarRisks) < 0) return space(similarRisks[d.id].similarCount) * x(1) * circlePlacementDx(d, similarRisks) + 5
	return 0
}

const circleDy = (d, similarRisks, y, space) => {
	if (similarRisks[d.id].similarCount == 0) return -3
	return space(similarRisks[d.id].similarCount) * y(1) * circlePlacementDy(d, similarRisks) + 11
}

const textDx = (d, similarRisks, x) => {
	if (Math.abs(circlePlacementDx(d, similarRisks) * 10) < 0.1) return 0

	if (circlePlacementDx(d, similarRisks) > 0) return 0.5 * x(1) * circlePlacementDx(d, similarRisks) - 3
	if (circlePlacementDx(d, similarRisks) < 0) return 0.5 * x(1) * circlePlacementDx(d, similarRisks) + 5
	return 0
}

const textDy = (d, similarRisks, y) => {
	if (similarRisks[d.id].similarCount == 0) return -3

	return 0.079 * y(1) * circlePlacementDy(d, similarRisks) + 11
}

const chartMargin = 0.25

export const RiskScatterChart = (props: any) => {
	const svgRef = useRef();

	const { likelihoods = [], impacts = [] } = props;

	const [hoveredRisk, setHoveredRisk] = React.useState(null);
	const [xy, setXY] = React.useState([0, 0]);

	const maxLikelihood = likelihoods && parseInt(maxBy(values(likelihoods), 'id')?.id || 3);
	const maxImpact = impacts && parseInt(maxBy(values(impacts), 'id')?.id || 3);

	const { style, data, isRiskView } = props;

	// Count the number of risks that have the same likelihood and impact
	const riskCount = data.reduce((acc, risk) => {
		const key = risk.impact + "-" + risk.likelihood;
		acc[key] = (acc[key] || 0) + 1;
		return acc;
	}, {});
	const similarRisks = keyBy((data || []).map(r => ({
		...r,
		similarCount: riskCount[r.impact + "-" + r.likelihood] - 1,
		index: data.filter(fr => fr.impact === r.impact && fr.likelihood === r.likelihood)
			.findIndex(d => d.id === r.id)
	})), "id");

	const margin = { top: 30, right: 30, bottom: 40, left: 40 },
		width = props.width - margin.left - margin.right,
		height = props.height - margin.top - margin.bottom;

	const marginFactor = 0.96

	const color = props.isRiskView ? "#E70549" : "#24CCB8";

	const x = d3.scaleLinear()
		.domain([marginFactor - chartMargin, maxImpact + chartMargin])
		.range([marginFactor, width]);
	const y = d3.scaleLinear()
		.domain([marginFactor - chartMargin, maxLikelihood + chartMargin])
		.range([height, 0]);
	const space = d3.scaleLinear()
		.domain([2, 9])
		.range([0.15, 0.3]);


	useEffect(() => {
		const svg = d3.select(svgRef.current)

		// Add X axis - without axis line
		let xAxisGenerator = d3.axisBottom(x);
		xAxisGenerator.tickSize(5)
		xAxisGenerator.ticks(maxImpact)

		// Add the axis label
		svg.append("text")
			.attr("text-anchor", "end")
			.attr("x", width / 2)
			.attr("y", height + margin.bottom - 5)
			.style("text-anchor", "middle")
			// Size
			.style("font-size", "12px")
			// Color
			.style("fill", "#FFFFFF")
			.text("Impact")


		svg.append("g")
			.attr("transform", `translate(0, ${height})`)
			.call(xAxisGenerator)
			.select("path, line")
			.remove();

		let yAxisGenerator = d3.axisLeft(y);
		yAxisGenerator.tickSize(5);
		yAxisGenerator.ticks(maxLikelihood);

		svg.append("g")
			.call(yAxisGenerator)
			.select("path, line")
			.remove();

		// Make the axis lines white
		svg.selectAll(".tick text, .tick line")
			.style("fill", "white");

		// Make the ticks white
		svg.selectAll("line")
			.style("stroke", "white");

		// Add the other axis label
		svg.append("text")
			.attr("x", -height / 2)
			.attr("y", -margin.left + 5)
			.attr("dy", ".75em")
			.attr("transform", "rotate(-90)")
			.style("text-anchor", "middle")
			// Size
			.style("font-size", "12px")
			// Color
			.style("fill", "#FFFFFF")
			.text("Likelihood")
	}, []);

	return (
		<div id="RiskScatterChart" style={{ ...style, position: "relative" }}>

			<svg
				width={width + margin.left + margin.right}
				height={height + margin.top + margin.bottom}
				style={style}>
				<defs>
					<radialGradient id="gradient" cx="95%" cy="5%" r="100%" >
						<stop offset="50%" stopColor={color} stopOpacity={1} style={{
							transition: "stop-color 0.5s ease-out"
						}} />
						<stop className="color" offset="240%" stopColor="#FFFFFF" stopOpacity={1} />
					</radialGradient>
				</defs>
				<g className="wrapper" transform={`translate(${margin.left}, ${margin.top})`} >
					<g className="axes" ref={svgRef}></g>
					<rect
						className="background"
						x={x(1 - chartMargin)}
						y={0}
						width={width - x(1 - chartMargin)}
						height={y(1 - chartMargin)}
						fill="url(#gradient)"
						style={{
							transition: "fill 2.5s"
						}}
					>
					</rect>
					{props.data.map((d, index) => {

						const isWorse = isRiskOrOpportunityWorse(d);
						const isBetter = isRiskOrOpportunityBetter(d);

						return <g key={d.id}
							onClick={() => {
								setHoveredRisk(null);
								if (props.onRiskClick) props.onRiskClick(d)
							}}
							onMouseOver={() => {
								setHoveredRisk(d);

								setXY([
									x(d.impact) + space(similarRisks[d.id].similarCount) * x(1) * circlePlacementDx(d, similarRisks),
									y(d.likelihood) + space(similarRisks[d.id].similarCount) * x(1) * circlePlacementDy(d, similarRisks)
								]);
							}}
							onMouseOut={() => {
								setHoveredRisk(null);
							}}
							style={{
								transform: `translate(${x(d.impact)}px, ${y(d.likelihood)}px)`,
								transition: "all 2.5s"
							}}
						>
							<circle
								className="risk-circle"
								cx={space(similarRisks[d.id].similarCount) * x(1) * circlePlacementDx(d, similarRisks)}
								cy={space(similarRisks[d.id].similarCount) * x(1) * circlePlacementDy(d, similarRisks)}
								r={4}
								fill={isWorse ? "#E70549" :
									isBetter ? "#24CCB8" :
										"#000000"
								}
								strokeWidth={isWorse ? "6" :
									isBetter ? "6" :
										"6"
								}
								stroke={isWorse && d.isOpportunity ? "#00000044" : isWorse && !d.isOpportunity ? "#FFFFFF55" :
									isBetter && d.isOpportunity ? "#FFFFFF55" : isBetter && !d.isOpportunity ? "#00000044" :
										"#00000001"
								}
								dx={circleDx(d, similarRisks, x, space)}
								dy={circleDy(d, similarRisks, y, space)}
								style={{
									transition: "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"

								}}
							>

							</circle>

							<circle
								className="risk-circle"
								cx={space(similarRisks[d.id].similarCount) * x(1) * circlePlacementDx(d, similarRisks)}
								cy={space(similarRisks[d.id].similarCount) * x(1) * circlePlacementDy(d, similarRisks)}
								r={4}
								fill={isWorse ? "#E70549" :
									isBetter ? "#24CCB8" :
										"#000000"
								}
								strokeWidth={isWorse ? "1" :
									isBetter ? "1" :
										"6"
								}
								stroke={isWorse ? "#FFFFFF" :
									isBetter ? "#FFFFFF" :
										"#00000001"
								}
								dx={circleDx(d, similarRisks, x, space)}
								dy={circleDy(d, similarRisks, y, space)}
								style={{
									transition: "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"

								}}
							>

							</circle>


							<text
								className="risk-label"
								x={0}
								y={0}
								dx={textDx(d, similarRisks, x)}
								dy={textDy(d, similarRisks, y)}
								fill={isWorse ? "#E70549" :
									isBetter ? "#24CCB8" : "#00000077"}
								strokeWidth={isWorse ? "#FFFFFF" :
									isBetter ? "#FFFFFF" :
										"6"
								}
								stroke={isWorse ? "#FFFFFF55" :
									isBetter ? "#FFFFFF55" :
										"#00000001"
								}
								style={{
									fontSize: "12px",
									fontWeight: "bold",
									textAnchor: Math.abs(circlePlacementDx(d, similarRisks) * 10) < 0.1 ? "middle" :
										circlePlacementDx(d, similarRisks) < 0 ? "end" :
											circlePlacementDx(d, similarRisks) > 0 ? "start" : "middle",
									alignmentBaseline: "middle",
									transition: "dx 2.5s, dy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"
								}}
								transform='translate(0, -10)'

							>
								{d.reference || (isRiskView ? "R" : "O") + ((d.index + 1 + "").padStart(2, "0"))}
								<circle
									r={2.5}
									fill="#000000"
								></circle>
							</text>

							<text
								className="risk-label"
								x={0}
								y={0}
								dx={textDx(d, similarRisks, x)}
								dy={textDy(d, similarRisks, y)}
								fill={"#000000"}
								style={{
									fontSize: "12px",
									textAnchor: Math.abs(circlePlacementDx(d, similarRisks) * 10) < 0.1 ? "middle" :
										circlePlacementDx(d, similarRisks) < 0 ? "end" :
											circlePlacementDx(d, similarRisks) > 0 ? "start" : "middle",
									alignmentBaseline: "middle",
									transition: "dx 2.5s, dy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"
								}}
								transform='translate(0, -10)'

							>
								{d.reference || (isRiskView ? "R" : "O") + ((d.index + 1 + "").padStart(2, "0"))}
								<circle
									r={2.5}
									fill="#000000"
								></circle>
							</text>



						</g>

					})}
				</g>
			</svg>

			<Tooltip className="tooltip" style={{ zIndex: 1000 }} title={hoveredRisk?.id && <div>
				<b>{hoveredRisk ? hoveredRisk.name : null}</b>

				<div className="mt-2">
					Impact: {impacts[parseInt(hoveredRisk.impact)] ? hoveredRisk.impact + " - " + impacts[parseInt(hoveredRisk.impact)]?.name : hoveredRisk.impact}
				</div>
				<div className="mb-2">
					Likelihood: {likelihoods[parseInt(hoveredRisk.likelihood)] ? hoveredRisk.likelihood + " - " + likelihoods[parseInt(hoveredRisk.likelihood)]?.name : hoveredRisk.likelihood}
				</div>

				<div className='text-center'>
					<small>
						Click to edit
					</small>
				</div>
			</div>} open={hoveredRisk}>
				<div id="Tooltip-root" key={hoveredRisk?.id || "empty"}
					style={{ zIndex: 1000, position: "absolute", top: xy ? xy[1] + margin.top - 15 + "px" : 0, left: xy ? xy[0] + margin.left + "px" : 0 }}>

				</div>
			</Tooltip>




		</div >
	);
};
