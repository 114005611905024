import { useTranslation } from 'react-i18next';

import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Tooltip } from 'antd';
import { getScoreColor } from 'utils/ranking';
import { FishbonePointPopover } from './FishbonePointPopover';

import './FishbonePoint.sass';
import _ from 'lodash';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime)

export const FishbonePoint = (props: any) => {
	const color = getScoreColor(props.trend);
	const { t } = useTranslation();

	let size = Math.min(8, props.size);

	let numberOfRaysMax = 39;
	let numberOfRays = Math.floor(
		((props.completion || 0) / 100) * numberOfRaysMax
	);

	const sizes = { 1: 5, 2: 7, 3: 9 };
	return (
		<Popover
			title={
				<div>
					{props.name}{' '}
					<FontAwesomeIcon
						style={{
							marginLeft: '0.5rem',
							fontSize: '1.2rem',
							cursor: 'pointer'
						}}
						icon={faEdit}
						onClick={() => props.onEdit(props)}
					/>
				</div>
			}
			trigger={'click'}
			content={<FishbonePointPopover {...props}></FishbonePointPopover>}
		>
			<g
				className="FishbonePoint"
				transform={'translate(' + props.x + ', ' + props.y + ')'}
			>
				<Tooltip
					overlayStyle={{ minWidth: '400px' }}
					title={
						<div>
							<div className="d-flex pb-3 ">
								<b>
									{props.name}{' '}
									<small
										style={{
											fontSize: '10px',
											marginLeft: '5px'
										}}
									>
										(click to edit)
									</small>
								</b>
							</div>

							<div className="d-flex mb-2">
								<div style={{ width: '110px', flexShrink: 0 }}>
									Completion
								</div>
								<div>
									<b>{(props.completion || 0).toFixed(0)}%</b>
								</div>
							</div>

							<div className="d-flex mb-2">
								<div style={{ width: '110px', flexShrink: 0 }}>
									Status
								</div>
								<div
									className="MiniIndicatorVulnerabilityScale"
									style={{
										width: '200px',
										marginTop: '10px'
									}}
								>
									<div
										id={'bar_marker'}
										style={{ left: props.trend + '%' }}
									></div>
								</div>
							</div>

							<div className="d-flex mb-2">
								<div style={{ width: '110px', flexShrink: 0 }}>
									Description
								</div>
								<div>
									<small>
										{props.description || 'No description'}
									</small>
								</div>
							</div>


							<div className="d-flex mb-2">
								<div
									style={{
										width: '110px',
										flexShrink: 0
									}}
								>
									Dates
								</div>
								<div>
									{
										<small>
											{props.startOn ? dayjs(props.startOn).format(
												'DD/MM/YYYY'
											) : t("messages.not_defined")}
										</small>
									}
								</div>
								{props.startOn && props.endOn && (
									<div className="mr-1 ml-1">-</div>
								)}
								<div>
									{props.endOn &&
										!props.startOn &&
										'Until '}
									{props.endOn && (
										<small>
											{dayjs(props.endOn).format(
												'DD/MM/YYYY'
											)}
										</small>
									)}
								</div>
							</div>


							{props.responsible && (
								<div className="d-flex mb-2">
									<div
										style={{
											width: '110px',
											flexShrink: 0
										}}
									>
										Responsible
									</div>
									<div>
										{props.responsible && props.responsible}
									</div>
								</div>
							)}

							{props.updatedAt && (
								<div className="d-flex">
									<div
										style={{
											width: '110px',
											flexShrink: 0
										}}
									>
										Last update
									</div>
									<div>
										{props.updatedAt &&
											dayjs(props.updatedAt).format(
												'DD/MM/YYYY HH:mm'
											)}
										<br />
										{props.updatedAt && (
											<small>
												(
												{dayjs(
													props.updatedAt
												).fromNow()}
												)
											</small>
										)}
									</div>
								</div>
							)}
						</div>
					}
				>
					<g>
						<g>
							<clipPath id="cut-off">
								<rect x="0" y="-9" width="18" height="18" />
							</clipPath>

							<circle
								r={sizes[props.weight || 1]}
								fill={props.trend == '50' ? '#E70549' : color}
							/>
							<circle
								r={sizes[props.weight || 1]}
								fill={props.trend == '50' ? '#24CCB8' : color}
								clipPath="url(#cut-off)"
							/>
						</g>
						<text
							fontSize={12}
							fill="white"
							dy={size * 0.65}
							dx={22}
						>
							{props.name}
						</text>
						{_.range(0, numberOfRays).map((r, index) => {
							return (
								<g
									key={index}
									className={'completion '}
									transform={
										'rotate(' +
										((index / numberOfRaysMax) * 360 - 90) +
										') '
									}
								>
									<line
										stroke="#FFFFFF66"
										x1={12}
										x2={15}
										y1={0}
										y2={0}
									></line>
								</g>
							);
						})}{' '}
					</g>
				</Tooltip>
			</g>
		</Popover>
	);
};
