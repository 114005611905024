import {
	UpdateProjectMutationVariables,
	useUpdateProjectMutation
} from 'graph/dist/generated';

export const useProjectController = () => {
	const [updateProject] = useUpdateProjectMutation();

	const handleUpdate = async (id, values) => {
		let newValues: UpdateProjectMutationVariables = { id };

		if (values.name != undefined) newValues.name = values.name;
		if (values.baselineScenarioDescription != undefined)
			newValues.baselineScenarioDescription =
				values.baselineScenarioDescription;
		if (values.baselineScenarioImpacts != undefined)
			newValues.baselineScenarioImpacts = values.baselineScenarioImpacts;

		const result = await updateProject({
			variables: {
				...newValues
			},
			update(cache) {
				let fields = {};

				if (newValues.name !== undefined)
					fields.name = () => newValues.name;
				if (newValues.baselineScenarioDescription !== undefined)
					fields.baselineScenarioDescription = () =>
						newValues.baselineScenarioDescription;
				if (newValues.baselineScenarioImpacts !== undefined)
					fields.baselineScenarioImpacts = () =>
						newValues.baselineScenarioImpacts;

				cache.modify({
					id: `Project:${id}`,
					fields
				});
			},
			optimisticResponse: {
				updateProject: true
			}
		});
	};

	return {
		updateProject: handleUpdate
	};
};
