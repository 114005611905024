import React from 'react';

import { omit, pick } from 'lodash';

import { fal } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import('./Item.sass');

function Item(props) {
	return (
		<div
			className={[
				'ItemWrapper',
				props.impact == 'medium' ? 'impactMedium' : '',
				props.impact == 'high' ? 'impactHigh' : '',
				props.trend == 'red' ? 'trendNegative' : '',
				props.trend == 'orange' ? 'trendAtRisk' : '',
				props.trend == 'green' ? 'trendPositive' : '',
				props.fade ? 'fade' : ''
			].join(' ')}
			{...pick(props, ['style', 'x', 'y', 'onMouseDown', 'onTouchStart', 'onDblClick'])}
		>
			<svg
				viewBox="-50 -50 100 100"
				className={
					'Item noselection ' + (props.selected ? 'selected' : '')
				}
			>
				<g>
					<circle
						className="selection"
						cx={0}
						cy={0}
						r={48}
						stroke="orange"
						strokeWidth={4}
						strokeDasharray="8 8"
						fill="none"
					></circle>
					<circle className="main" cx={0} cy={0} r={30}></circle>

					<FontAwesomeIcon
						x="-17.5"
						y="-18"
						width={35}
						height={35}
						color="black"
						icon={props.icon ? fal[props.icon] : fal['faUser']}
					></FontAwesomeIcon>
				</g>
			</svg>
			<div className="ItemText noselection">{props.name}</div>
		</div>
	);
}

export default Item;
