import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Tag } from "antd";
import Weight from "components/controls/Weight";
import { keyBy, sortBy } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getScoreColor } from "utils/ranking";

interface StakeholdersTabProps {
    selection: any;
    onEdit: Function;
    onClone: Function;
    onClose: Function;
    onDelete: Function;
    scenario: any;

    stakeholders: any[];
    createRiskImpact: Function;
    deleteRiskImpact: Function;
    updateImpactStrength: Function;
}

export const StakeholdersTab = (props: StakeholdersTabProps) => {

    const { stakeholders = [] } = props;

    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState<any | null>('');

    let impactedStakesholders = stakeholders.filter(
        (s) => s.impactedRisks.some((ir) => ir.riskId === props.selection?.id)
    );
    const indexedStakeholders = keyBy(impactedStakesholders, 'id');

    console.log("impactedStakesholders", impactedStakesholders);


    // Filter based on search term
    const filteredStakeholders = stakeholders.filter(
        (s) =>
            s.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            s.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="StakeholdersTab">

            <div className="d-flex flex-column flex-grow">

                <div className="d-flex flex-row align-items-center mb-2">
                    <div>{props.selection?.name}</div>
                    <Button
                        shape="circle"
                        className="mini ml-auto"
                        tooltip={t('models.item.actions.edit')}
                        style={{ marginRight: '-1rem' }}
                        onClick={() => props.onClose()}
                    >
                        <FontAwesomeIcon icon={faClose} />
                    </Button>
                </div>
            </div>


            <Input
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                }}
                value={searchTerm}

                placeholder="Search"
                className="SearchInput w-100"
            ></Input>

            {(
                <div className="d-flex flex-column flex-grow mt-3 mb-4">

                    <div className="text-left">
                        {filteredStakeholders && filteredStakeholders.length ? (
                            sortBy(filteredStakeholders, 'name').map(
                                (s: any, index) => {

                                    const impactedStakeholder = indexedStakeholders[s.id] && indexedStakeholders[s.id].impactedRisks?.find(ir => ir.riskId === props.selection?.id);

                                    return (
                                        <Tag
                                            key={index}
                                            onClick={async () => {

                                                if (
                                                    !impactedStakeholder ||
                                                    !impactedStakeholder.id
                                                ) {
                                                    props.createRiskImpact(
                                                        s.id,
                                                        props.selection?.id
                                                    );
                                                } else if (
                                                    impactedStakeholder.strength <
                                                    3
                                                ) {
                                                    props.updateImpactStrength(
                                                        impactedStakeholder,
                                                        impactedStakeholder.strength +
                                                        1
                                                    );
                                                } else {
                                                    console.log("will delete", impactedStakeholder);
                                                    props.deleteRiskImpact(
                                                        impactedStakeholder.id,
                                                        s.id
                                                    );
                                                }
                                            }}
                                            className={[
                                                'ImpactedIndicator tag',
                                                impactedStakeholder
                                                    ? 'selected'
                                                    : 'unselected'
                                            ].join(' ')}
                                        >
                                            <div
                                                className={
                                                    'Trend v' + s.trend
                                                }
                                                style={
                                                    s.trend == '50'
                                                        ? {}
                                                        : {
                                                            background:
                                                                getScoreColor(
                                                                    s.trend
                                                                )
                                                        }
                                                }
                                            ></div>
                                            {s.name}

                                            {impactedStakeholder ? (
                                                <Weight onClick={() => { }}>
                                                    {
                                                        impactedStakeholder.strength
                                                    }
                                                </Weight>
                                            ) : null}
                                        </Tag>
                                    );
                                }
                            )
                        ) : (
                            <div
                                className="text-center"
                                style={{ color: 'rgba(46, 160, 223, 0.3)' }}
                            >
                                {t('models.item.no_x')}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}