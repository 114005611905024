// @ts-nocheck
import { Tag } from 'antd';
import { max } from 'd3';
import { keyBy, maxBy, range, rangeRight } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import "./RiskPopover.sass"

type RiskPopoverProps = {
    values: object[];
    onChange: (value: number) => void;
}

export function RiskPopover(props: RiskPopoverProps) {

    const max = maxBy(props.values || [], "id")
    const values = range(
        1,
        (max?.id? parseInt(max?.id) : 3)
        +0.5,
        0.5,
    )

    const indexedValues = keyBy(props.values || [], "id");

	return (
        <div className={"RiskPopover"}>
            {(values).map((l: any) => {
                const value = indexedValues[parseInt(l)];

                return  <Tag onClick={() => props.onChange(l)} className={props.value==l? "selected":""} key={l}>
                    {l} {value && value.name? " - "+value.name : ""}
                </Tag>
            }
               
            )}
        </div>
    );
}

export default RiskPopover;
