export default {
	title: 'Risks / Opportunities',
	url: 'risks',
	subtitle: '',
	all_categories: 'Categories filter',
	all_impacts: 'Impact filter',
	all_likelihoods: 'Likelihood filter',
	all_stakeholders: 'Stakeholders filter',
	all_stakes: 'Stakes filter',
	no_stakeholder: 'No stakeholder',
	tabs: {
		filters: {
			title: 'View filters',

			displayed_columns: 'Displayed columns'
		},
		options: {
			title: 'Display'
		},
		general: {
			title: 'General'
		},
		stakeholders: {
			title: 'Stakeholders'
		}
	}
};
