import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import {
	useDeleteProjectMutation,
	useOnCreateProjectSubscription,
	useCloneProjectMutation
} from 'graph/dist/generated';

import ProjectItem from './ProjectItem';
import Loader from 'components/Loading/Loader';
import { ConfirmModal } from 'components/Modal/ConfirmModal';
import { useListProjectsQuery } from 'graph/queries';

export const ProjectsList = (props: any) => {
	const { t } = useTranslation();

	const [deleteData] = useDeleteProjectMutation();
	const [cloneData] = useCloneProjectMutation();

	/*if (projectSubscription.data && refetch && projectSubscription.data.id && projectSubscription.data.id!=lastAddition) {
	console.log("refetch");
	//setLastAddition(projectSubscription.data.id);
	refetch();
  }*/

	//console.log(projectSubscription);

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.project.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDelete(id)}
				message={t(
					'models.project.messages.delete_confirmation_explanation'
				)}
			></ConfirmModal>
		);
	};

	const askCloneConfirmation = async (id?: string) => {
		if (id == null) {
			return;
		}

		props.pushModal(
			<ConfirmModal
				title={t('models.project.messages.clone_confirmation')}
				t={t}
				onConfirm={() => handleClone(id)}
				message={t(
					'models.project.messages.clone_confirmation_explanation'
				)}
			></ConfirmModal>
		);
	};

	const handleClone = async (id?: string) => {
		if (id == null) {
			return;
		}

		await cloneData({
			variables: { id }
		});

		await props.refetch();
	};

	const handleDelete = async (id?: string) => {
		console.log('deleteData', id);

		if (id == null) {
			return;
		}

		await deleteData({
			variables: { id }
			/*update: async (store) => {
				let data: any = await store.readQuery({
					query: ListProjectsDocument,
				})

				console.log('handleDelete data', data)

				store.writeQuery({
					query: ListProjectsDocument,
					data: {
						listProjects: {
							...data.listProjects,
							items: data.listProjects.items.filter((o: any) => o.id !== id),
						},
					},
				})
			},*/
		});

		await props.refetch();
	};

	return (
		<>
			{
				props.list?.map((value: any, index: Number) =>
					index == 0 && props.tutorialPopover ? (
						React.cloneElement(props.tutorialPopover, {
							key: value?.id,
							children: (
								<ProjectItem
									onDelete={() => {
										askDeleteConfirmation(value?.id);
									}}
									onClick={props.onSelectProject}
									onClone={
										props.canClone
											? (e: any) => {
												console.log(
													'onClone',
													value?.id,
													e
												);
												askCloneConfirmation(
													value?.id
												);
												return false;
											}
											: null
									}
									key={value?.id}
									details={value}
								></ProjectItem>
							)
						})
					) : (
						<ProjectItem
							onDelete={() => {
								askDeleteConfirmation(value?.id);
							}}
							onClick={props.onSelectProject}
							onClone={
								props.canClone
									? (e: any) => {
										console.log(
											'onClone',
											value?.id,
											e
										);
										askCloneConfirmation(value?.id);
										return false;
									}
									: null
							}
							key={value?.id}
							details={value}
						></ProjectItem>
					)
				)
			}
		</>
	);
};
