import React from 'react';

import { faClone, faClose, faEdit, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionButton from "components/action/ActionButton";

import { useTranslation } from "react-i18next";

import "./RiskGeneralTab.sass";
import { Button, Tag } from 'antd';
import { find, keyBy, sortBy } from 'lodash';
import { getScoreColor } from 'utils/ranking';
import Weight from 'components/controls/Weight';

interface RiskGeneralTabProps {
    selection: any;
    onEdit: Function;
    onClone: Function;
    onClose: Function;
    onDelete: Function;
    scenario: any;

    categories: any[];

    indicators: any[];
    createIndicatorImpact: Function;
    deleteIndicatorImpact: Function;
    updateImpactStrength: Function;
}

const getImpactingIndicatorDetails = (impactedIndicators, indicatorId: string) => {
    return find(
        impactedIndicators || [],
        (ii: any) => ii.indicatorId == indicatorId
    );
};

const isImpactingIndicator = (indexedIndicators, impactedIndicators, indicatorId: string) => {
    if (
        indexedIndicators[indicatorId] &&
        impactedIndicators
            ?.map((ii: any) => ii.indicatorId)
            .includes(indicatorId)
    )
        return true;
    return false;
};

export const RiskGeneralTab = (props: RiskGeneralTabProps) => {

    const { categories = [], indicators = [] } = props;
    const { t } = useTranslation();

    const indexedIndicators = keyBy(indicators, 'id');

    const category = categories.find(
        (c: any) => c.id === props.selection?.riskCategoryId
    );

    return (
        <div className="RiskGeneralTab">

            <div className="d-flex flex-column flex-grow">

                <div className="d-flex flex-row align-items-center">
                    <div>{props.selection?.name}</div>
                    <Button
                        shape="circle"
                        className="mini ml-auto"
                        tooltip={t('models.item.actions.edit')}
                        style={{ marginRight: '-1rem' }}
                        onClick={props.onClose}
                    >
                        <FontAwesomeIcon icon={faClose} />
                    </Button>
                </div>

                <ul>

                    <li className="EditableListItem">
                        <div style={{ width: '40%', textAlign: 'left' }}>
                            {t('models.risk.fields.risk_category')}
                        </div>
                        <div>
                            {category ? category.name : ''}
                        </div>
                    </li>
                    {props.selection?.description && (
                        <li className="EditableListItem">
                            <div>{props.selection?.description}</div>
                        </li>
                    )}
                </ul>

                {!props.scenario && (
                    <div className="Actions mt-2 mb-4 pl-1 text-center ml-auto mr-auto">
                        <ActionButton
                            className="small ml-05"
                            tooltip={t('models.item.actions.edit')}
                            onClick={() => props.onEdit(props.selection.id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </ActionButton>

                        {false && <ActionButton
                            className="small"
                            tooltip={t('models.item.actions.clone')}
                            onClick={() => props.onClone(props.selection)}
                        >
                            <FontAwesomeIcon icon={faClone} />
                        </ActionButton>}

                        <ActionButton
                            className="small"
                            tooltip={t('models.item.actions.delete')}
                            onClick={() => props.onDelete(props.selection.id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </ActionButton>
                    </div>
                )}

                {(
                    <div className="d-flex flex-column flex-grow mt-3 mb-4">
                        <div className="text-left mb-05">
                            {t('models.item.fields.indicators.name')}
                        </div>
                        <div className="text-left">
                            {indicators && indicators.length ? (
                                sortBy(indicators, 'name').map(
                                    (i: any, index) => {
                                        const impactedIndicator =
                                            getImpactingIndicatorDetails(props.selection?.impactedIndicators || [], i.id);

                                        return (
                                            <Tag
                                                key={index}
                                                onClick={async () => {

                                                    if (
                                                        !impactedIndicator ||
                                                        !impactedIndicator.id
                                                    ) {
                                                        props.createIndicatorImpact(
                                                            props.selection?.id,
                                                            i.id
                                                        );
                                                    } else if (
                                                        impactedIndicator.strength <
                                                        3
                                                    ) {
                                                        props.updateImpactStrength(
                                                            impactedIndicator,
                                                            impactedIndicator.strength +
                                                            1
                                                        );
                                                    } else {
                                                        props.deleteIndicatorImpact(
                                                            impactedIndicator.id,
                                                            props.selection?.id
                                                        );
                                                    }
                                                }}
                                                className={[
                                                    'ImpactedIndicator tag',
                                                    isImpactingIndicator(indexedIndicators, props.selection?.impactedIndicators || [], i.id)
                                                        ? 'selected'
                                                        : 'unselected'
                                                ].join(' ')}
                                            >
                                                <div
                                                    className={
                                                        'Trend v' + i.trend
                                                    }
                                                    style={
                                                        i.trend == '50'
                                                            ? {}
                                                            : {
                                                                background:
                                                                    getScoreColor(
                                                                        i.trend
                                                                    )
                                                            }
                                                    }
                                                ></div>
                                                {i.name}

                                                {impactedIndicator ? (
                                                    <Weight onClick={() => { }}>
                                                        {
                                                            impactedIndicator.strength
                                                        }
                                                    </Weight>
                                                ) : null}
                                            </Tag>
                                        );
                                    }
                                )
                            ) : (
                                <div
                                    className="text-center"
                                    style={{ color: 'rgba(46, 160, 223, 0.3)' }}
                                >
                                    {t('models.indicator.no_x')}
                                </div>
                            )}
                        </div>
                    </div>
                )}


            </div>
        </div>
    );
}